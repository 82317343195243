import timeToSeconds from './timeToSeconds'
import { keysAllowed } from '../config'

export default (key, pads, handler) => {
  key = String.fromCharCode(
    96 <= key && key <= 105 ? key - 48 : key
  ).toLowerCase()
  if (keysAllowed.includes(key)) {
    const noteData = pads.find(data => data.key === key)
    if (noteData && noteData.time && typeof handler === 'function') {
      handler(timeToSeconds(noteData.time))
    }
  } else {
  }
}
