import { useState, useEffect } from 'react'

export default () => {
  const [keyPressed, setKeyPressed] = useState(false)
  const [shouldSequencerRecord, updateSequencerRecord] = useState(false)

  function downHandler(e) {
    setKeyPressed(e)
    updateSequencerRecord(!shouldSequencerRecord)
  }

  function upHandler(e) {
    setKeyPressed(false)
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [])

  return [keyPressed, shouldSequencerRecord]
}
