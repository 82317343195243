// extracted by mini-css-extract-plugin
export var grooveSelect = "style-module--grooveSelect--2P2a3";
export var toaster = "style-module--toaster--3GbpA";
export var sampler = "style-module--sampler--3k-TJ";
export var embedResponsive = "style-module--embedResponsive--2_XhA";
export var embedResponsiveItem = "style-module--embedResponsiveItem--hM_VO";
export var samplerAbout = "style-module--samplerAbout--12Xo2";
export var switchPageBtn = "style-module--switchPageBtn--3e0sX";
export var moviesAbout = "style-module--moviesAbout--NK3yl";
export var videoSwitcher = "style-module--videoSwitcher--y6Ern";
export var videoAboutText = "style-module--videoAboutText--1IEM1";