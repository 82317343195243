import * as Tone from 'tone'

export default function(player) {
  if (typeof document !== 'undefined') {
    document.addEventListener('click', async function toneHelper() {
      await Tone.start()
      Tone.Transport.start()
      Tone.Transport.value = 100
      console.log('audio is ready')
      document.removeEventListener('click', toneHelper)
      player.play()
      //callback && callback()
    })
  }
}
