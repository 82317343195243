import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import * as styles from './styles.module.css';
import { videos, help, about, pads } from 'src/config/index';
import { Popover } from 'react-tiny-popover';
import eye from 'src/images/eye.png';
import close from 'src/images/close.svg';
import logo from 'src/images/logo.svg';
import {isMobile } from "react-device-detect";
import { timeToSeconds } from '../../utils';
  
const Header = ({
  // content,
  // buttonText,
  onOpen,
  changeVideo,
  // timeChange,
  setBinding,
  binding,
  // sequence,
  pads,
  videoId,
  hideUI,
  isUIHidden,
  setPads,
  setTimeToPad
}) => {

  let [opened, openPop] = useState(false);
  let [openedType, changeType] = useState('help');
  // let [elementsHidden, hideElements] = useState(false);
  let [inputtedUrl, inputUrl] = useState('');
  let [shareURL, updateURL] = useState('/');
  let [helpText, setHelpText] = useState('top');

  useEffect (() => {
    isMobile ?
    setHelpText('topMobile')
    : setHelpText('top');
  }, [helpText])
  
  useEffect(() => {
    onOpen && onOpen(opened);
  }, [opened]);

  // useEffect(() => {
  //   if (openedType == 'help' || openedType == 'about') {
  //     document.addEventListener('click', closeHelpOnClick)
  //   }
  // }, [openedType])

  // function closeHelpOnClick() {
  //   changeType('')
  //   document.removeEventListener('click', closeHelpOnClick)
  // }

  useEffect(() => {
    if (!binding && window.prev_binding == true && openedType != '') {
      window.prev_binding = binding
      changeType('')
    }
  }, [binding])

  function closeModals ({target}) {
    console.log(target);
    const validClassList = ['help-container', 'start-btn', 'closePopUp'];
    validClassList.forEach(item => {
      target.classList.contains(item) && changeType('');
    })
  }

  let headerButtons = [
    {
      text: "New",
      onClick: (item) => {
        openPop(!opened);
        openedType ? changeType('') : changeType(item);
      },
      header: "",
      cta: "Select",
      ctaOnClick: () => { },
      input: true,
      popOverClassName: styles.selectPlayPopover,
      align: "start",
      group: 'left'
    },
    {
      text: "Set",
      className: styles.bindButton,
      onClick: (item) => {
        openPop(!opened);
        openedType ? changeType('') : changeType(item);
        setBinding(!binding);
        window.usedBinding = true
      },
      header: "Click the color circle to set new time",
      cta: "Copy link",
      align: "center",
      popOverClassName: styles.popoverSetInner

    },
    {
      text: "Share",
      onClick: (item) => {
        openPop(!opened);
        let padsArray = []
        pads.map(item => {
          padsArray.push(item.time)
        })
        let encodedArray = window.btoa(window.encodeURIComponent(padsArray.toString()));
        let finalUrl = window.location.origin + '?vid=' + videoId + (window.usedBinding ? '&pads=' + encodedArray : '');
        updateURL(finalUrl);
        console.log(shareURL);
        openedType ? changeType('') : changeType(item);
      },
      header: "Share and play",
      popOverClassName: styles.sharePopover,
      cta: "Copy link",
      group: 'left',
      align: "start",
    },
    {
      text: "About",
      onClick: (item) => {
        openedType ? changeType('') : changeType('about');
      },
      group: 'right',
      align: "end",
    },
    {
      text: "Help",
      onClick: (item) => {
        openedType ? changeType('') : changeType('help');
      },
      group: 'right',
      align: "end",
    },
 
  ];

  let handleSubmit = (e, video) => {
    changeVideo(video.url || inputtedUrl);
    e && e.preventDefault();
  };

  function copyTextToClipboard(text) {
    var dummy = document.createElement("textarea");
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

  let popover = (item, type) => {
    return (
      <>
        <div className={cx('popover', { [item.popOverClassName]: !!item.popOverClassName })}>
        {item.header && <span className={styles.popoverHeader}>{item.header}</span>}
          {item.text == "New" ? (
            <>
              <form className={styles.selectPlayForm} onSubmit={handleSubmit}>
                <input
                  className={styles.selectPlayField}
                  type="text"
                  placeholder="Insert vimeo link"
                  value={inputtedUrl}
                  onChange={(e) => inputUrl(e.target.value)}
                />
                <button
                  type="submit"
                  className={styles.selectPlayBtn}
                ></button>
              </form>
              <div className={styles.selectPlayVideosContainer}>
                {videos.map((item, key) => (
                  <div
                    className={styles.video}
                    key={key}
                    onClick={() => {
                      inputUrl(item.url);
                      handleSubmit(false, item);
                      openPop(false)
                    }}
                    style={{ backgroundImage: `url(${item.img})` }}
                  ><span>{item.title}</span></div>
                ))}
              </div>
              <img src={close} onClick={() => openPop(!opened)} className={styles.popoverClose} />
            </>) : ''
          }
          {item.text == 'Share' && (
            <>
              <div className={styles.shareForm}>
                <input
                  value={shareURL}
                  placeholder="Share this link with some one"
                  className={styles.shareField}
                  onChange={e => updateURL(e.target.value)}
                />
                <button
                  className={styles.shareBtn}
                  onClick={() => copyTextToClipboard(shareURL)}
                >Copy it</button>
              </div>
              <img src={close} onClick={() => openPop(!opened)} className={styles.popoverClose} />
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="absolute" style={openedType !== '' ? {zIndex: 100, pointerEvents: 'auto'} : {}}>
        <div className={cx(
          "header-container flex-container-row",
          isUIHidden && styles.hideUI
          )}>

          {/* popup */}
          {openedType == 'help' || openedType == 'about' ? 
            <div className="flex-container-column help-container" onClick={closeModals}>
              <div className="flex-container-row help-top-pills">
                <img src={close} alt="close"  className={'closePopUp'}/>
             
                {/* help popup */}
                { openedType == 'help' && 
                  <div className={styles.helpControlsItems}>
                    {pads.map((item, key) => {
                      return <div className={styles.helpControlsItem} 
                                  style={{backgroundColor: item.bg}}
                                  key={key}>
                                  {item.key}
                              </div>
                    })}
                  </div>
                }  

                { openedType == 'help' && help[helpText].map((item, key) => {
                  return <p className='flex-element help-pill' key={key} dangerouslySetInnerHTML={{__html: item}}></p>
                })}

                {/* about popup */}
                {openedType == 'about' && <div className='flex-element help-pill'>{about.text}</div>}

                <div className='start-button'>
                  <button className="start-btn">START</button>
                </div>

              </div>
            </div>
            : null
          }

          <div className={styles.headerLeft}>
            {headerButtons.filter(i => i.group === 'left').map((item, key) => {
              return (
                <div className={cx(styles.btn, { [item.className]: !!item.className })} key={key}>
                  <Popover
                    onClickOutside={() => {
                      changeType('');
                      openPop(false)}}
                    isOpen={opened && openedType == item.text}
                    positions={['bottom']}
                    align={item.align}
                    containerClassName={'popoverWrapper'}
                    // contentLocation={'top: 0', 'left: 0'}
                    content={popover(item)}
                  >
                    <button onClick={() => item.onClick(item.text)}>
                      {item.text}
                    </button>
                  </Popover>
           
                </div>
              );
            })}
          </div>
          <div className={styles.headerCenter}>
            {headerButtons.filter(i => !i.group).map((item, key) => {
              return (
                <div className={cx(styles.btn, { [item.className]: !!item.className })} key={key}>
                  <Popover
                    onClickOutside={() => {
                      changeType('');
                      openPop(false)}}
                    isOpen={opened && openedType == item.text}
                    // positions={["bottom"]}
                    containerClassName={'popoverWrapperSet'}
                    align={item.align}
                    content={popover(item)}
                  >
                    <button onClick={() => item.onClick(item.text)}>
                      {item.text == 'Eye' ? <img src={eye} /> : item.text}
                    </button>
                  </Popover>
                </div>
              );
            })}
          </div>

          <div className={styles.headerRight}>
            {headerButtons.filter(i => i.group === 'right').map((item, key) => {
              return (
                <div className={cx(styles.btn, { [item.className]: !!item.className })} key={key}>
                  <button onClick={() => item.onClick()}>
                      {item.text}
                  </button>
                </div>
              );
            })}

            {/* logo */}
            <a href="https://seeingsound.playtronica.com/" className={styles.logo}>
              <img src={logo} alt="logo" />
            </a>
          </div>

        </div>
        {/* hide UI Button */}
        <div className={cx(
              styles.hideUIBtn,
              isUIHidden && styles.hideUIBtnActive)}
              onClick={() => hideUI(!isUIHidden)}>
              {isUIHidden ? "Menu" : "Hide"} 
          </div>
      </div>
    </>
  );
};

export default Header
