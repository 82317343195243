import React, { useEffect, useState } from "react";
import cn from "classnames";
import * as styles from "./styles.module.css";
import * as Tone from "tone";
import sequenceGenerator from "../../utils/sequenceGenerator";

const Sequencer = ({
  pads,
  seekTime,
  toggleRecording,
  recordedNote,
  isRecording,
  recordNote,
  sequence,
  updateSequence,
  currentNote,
  updateCurrent,
  isUIHidden
}) => {

  // console.log("SEQUENCER UPDATE");

  return (
    <div className={cn(
      styles.sequencer,
      isUIHidden && styles.hideUI
    )}>
        <button
        style={{ color: isRecording ? 'red' : 'white'}}
        className='rec'
          onClick={() =>{
            let currentRecordingState = isRecording
            toggleRecording(!currentRecordingState)
            if (!currentRecordingState && sequence.length > 1) {
               updateSequence(sequenceGenerator())
            }
          }}
        >
          Rec
        </button>        

        {
          <div className={styles.sequence}>
            {false && sequence.map((item, key) => {
              return (
                <div
                  className={cn(
                    styles.sequenceItem,
                    isRecording && styles.recording,
                    currentNote == key && styles.activeItem
                  )}
                  key={key}
                >
                  [{item ? item : ''}]
                </div>
              );
            })}
          </div>
          }
      </div>
  );
};

export default Sequencer;
