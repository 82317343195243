import React from 'react';
import cx from 'classnames';
import { timeToSeconds } from 'src/utils';
import * as styles from './styles.module.css';

const Pad = ({ pad, playerHandler, uniqId, timeChange, binding, setBinding, recording, recordNote }) => {
  return (
    <div className={styles.padWrapper}>
      <div className={styles.padShadow} />
      <div
        id={pad.key}
        style={{ background: pad.bg }}
        className={cx(styles.pad, 'unselectable')}
        onClick={() => {
          if (binding) {
            pad = timeChange(pad)
            setBinding(!binding)
          } else {
            playerHandler(timeToSeconds(pad.time))
            if (recording) recordNote(pad.time)
          }
        }}
      />
    </div>
  );
};

export default Pad;
