import React, { useState, useEffect } from 'react'
import * as styles from './styles.module.css'
import Fade from 'react-reveal/Fade'
import VideoContainer from '../../components/VideoContainer'

const MainFrame = () => {
  const [content, changeContent] = useState(null)
  const [menuHidden, setMenuHidden] = useState(false)
  const [activeItem, updateActive] = useState(0)

  useEffect(() => {
    changeContent(
      <VideoContainer
        changeContent={changeContent}
        setMenuHidden={setMenuHidden}
        updateActive={updateActive}
      />
    )
  }, [])

  return (
    <Fade>
      <div className={styles.mainFrame}>
        {content}
      </div>
    </Fade>
  )
}

export default MainFrame
