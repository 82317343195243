// extracted by mini-css-extract-plugin
export var btn = "styles-module--btn--bnjXn";
export var bindButton = "styles-module--bindButton--2UBJe";
export var sharePopover = "styles-module--sharePopover--GqaqT";
export var shareForm = "styles-module--shareForm--1TA9d";
export var shareField = "styles-module--shareField--tebQL";
export var shareBtn = "styles-module--shareBtn--3N3xa";
export var popoverHeader = "styles-module--popoverHeader--3QRWA";
export var popoverClose = "styles-module--popoverClose--2Kud5";
export var selectPlayPopover = "styles-module--selectPlayPopover--3Hp-Y";
export var selectPlayForm = "styles-module--selectPlayForm--2pLe6";
export var selectPlayField = "styles-module--selectPlayField--5AqJp";
export var selectPlayBtn = "styles-module--selectPlayBtn--2GBIi";
export var selectPlayVideosContainer = "styles-module--selectPlayVideosContainer--Xkan-";
export var popoverSetInner = "styles-module--popoverSetInner--IPNMi";
export var video = "styles-module--video--1ZVqd";
export var headerLeft = "styles-module--headerLeft--wd9P2";
export var headerCenter = "styles-module--headerCenter--3mtQc";
export var headerRight = "styles-module--headerRight--W0ULv";
export var logo = "styles-module--logo--3i8yT";
export var setBtn = "styles-module--setBtn--1Ry6s";
export var hideUIBtn = "styles-module--hideUIBtn--M3LH7";
export var helpControlsItems = "styles-module--helpControlsItems--1WFT3";
export var helpControlsItem = "styles-module--helpControlsItem--24YZD";
export var hideUIBtnActive = "styles-module--hideUIBtnActive--C3_Hp";
export var hideUI = "styles-module--hideUI--1o813";