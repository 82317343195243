import React, { useEffect } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import MainFrame from '../components/MainFrame'
import { toneHelper } from '../utils'

const IndexPage = () => {

  return (
    <Layout>
      <SEO title="Videosampler" />
      <MainFrame />
    </Layout>
  )
}

export default IndexPage
