import * as Tone from 'tone'
import WebMidi from 'webmidi'

export default (duration, handler) => {
  if (typeof window !== 'undefined') {
  
    // navigator.requestMIDIAccess().then(midiAccess => {
    //   Array.from(midiAccess.inputs).forEach(input => {
    //     let deviceName = input.filter(i => i.name == "TouchMe")?.[0]?.name
    //     input[1].onmidimessage = (msg) => {
    //       if (msg.data[2] > 0) {
    //         if (deviceName && deviceName == "TouchMe") handleMidiChange("TouchMe")
    //         handleNoteChange(Tone.Frequency(msg.data[1], "midi")._val)
    //       }
    //     }
    //   })
    //   console.log(Array.from(midiAccess.inputs))
    // });

    WebMidi.enable(function(err) {
      if (err) console.log("An error occurred", err);
   
      WebMidi.inputs.forEach(i => i.addListener("noteon", "all", function(e) {
        //synth.triggerAttackRelease(e.note.name + e.note.octave, '8n', '+0.5');
        let port_array = Array.from(Array(duration).keys())
        let portions = splitToChunks(port_array,127)
        console.log(portions)
        let portion_key;
        portions.map((item,key) => {
            if (item.includes(e.note.number)) {
                portion_key = item[0] + 3
            }
        })
        console.log(portion_key)
        handler(portion_key)
        //synth2.triggerAttackRelease(note, '1n', '+0.1');
        //if (deviceName && deviceName == "TouchMe") handleMidiChange("TouchMe")
      }));
   
    });
  }
}

function splitToChunks(array, parts) {
    let result = [];
    for (let i = parts; i > 0; i--) {
        result.push(array.splice(0, Math.ceil(array.length / i)));
    }
    return result;
}