import React from 'react'

export const videos = [
  {
    url: 'https://vimeo.com/576782182',
    title: 'OMMA SET #1',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-f8f53c4f-5a34-4481-81c9-6293e8399fc1.jpg',
    pads_config: ['0:00','0:05','0:10','0:20','0:30','0:35','0:40','0:45','0:50','0:55']
  },
  {
    url: 'https://vimeo.com/257727748',
    title: 'An Optical Poem',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-f8f53c4f-5a34-4481-81c9-6293e8399fc1.jpg'
  }, 
  {
    url: 'https://vimeo.com/15605448',
    title: 'Synchronomy',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-66b17f08-47b4-4c4f-a2db-3bd305734fe5.jpg'
  },
  {
    url: 'https://vimeo.com/184949510',
    title: 'CMYK',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-ab5a3029-055f-4c48-abf4-af8c9eae7f28.jpg'
  },
  {
    url: 'https://vimeo.com/9188773',
    title: 'Neighbors',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-2797b9d3-d02d-4fda-af4f-e9c41636bc05.jpg'
  },
  {
    url: 'https://vimeo.com/200763647',
    title: 'Mandala',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-74980023-d5bd-4e4a-a100-6d9dbbfe2132.jpg'
  },
  {
    url: 'https://vimeo.com/212827684',
    title: 'Fugue',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-3d7f1d47-0eeb-4d5c-be2f-6abb1b0eb054.jpg'
  }, {
    url: 'https://vimeo.com/40184263',
    title: 'Pas de deux',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-64e5e9f6-3f41-4453-a976-2525af0795e4.jpg'
  }, {
    url: 'https://vimeo.com/74963370',
    title: 'Diagonal Symphony',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-07e2a4a0-e189-4d32-b08d-4ad7f87c7a7f.jpg'
  }, {
    url: 'https://vimeo.com/180422974',
    title: 'Balance',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-602cbce0-73f5-4f28-a0dd-39fb10f65142.jpg'
  }, {
    url: 'https://vimeo.com/262027844',
    title: 'Lichtspiel Opus I',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-06cc18b3-65c7-4b87-b183-a282afe4c6cd.jpg'
  }, {
    url: 'https://vimeo.com/440510795',
    title: 'Somnambulists',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-166a21f3-a6b4-4052-91d2-cb10aee6c4a5.jpg'
  }, {
    url: 'https://vimeo.com/240347841',
    title: 'Early Abstractions - No.7 Color Study',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-1e7601fc-7263-473b-a2cd-d6eec069104a.jpg'
  },
  {
    url: 'https://vimeo.com/262031878',
    title: 'Der Sieger',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-30ebc7b6-bc34-47be-89b0-ab091b5f61d5.jpg?e=webp'
  }
  , {
    url: 'https://vimeo.com/129415470',
    title: 'The Birth of the Robot',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-3e32652d-dbe8-49d9-848b-c9abc0f7e859.jpg?e=webp'
  },
  {
    url: 'https://vimeo.com/216352467',
    title: 'Early Abstractions - No.7 Color Study',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-50f09db1-369d-4d4c-81dc-bab9d6b69ff7.jpg?e=webp'
  },
  {
    url: 'https://vimeo.com/27144448',
    title: 'Notes on a Triangle',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-d27861d6-4811-4811-a3c6-b0a4c97d37cc.jpg?e=webp'
  },
  {
    url: 'https://vimeo.com/547244754',
    title: 'Mirror Box',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-adb1d1fe-e46d-43b5-be86-f24b60f4936c.jpg?w=600&e=webp'
  },


  {
    url: 'https://vimeo.com/32645760',
    title: 'Dots',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-0d3df416-5e3b-45dc-8392-0582591aa16a.jpg?e=webp'
  },
  {
    url: 'https://vimeo.com/2375069',
    title: 'Manual Input Workstation',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-99dbc5d8-bddb-4f7f-b86e-253602fe797b.jpg?e=webp'
  },
  {
    url: 'https://vimeo.com/328690392',
    title: 'Someday',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-c75ef5af-5683-4f26-a6f7-d143115c53a0.jpg?w=600&e=webp'
  },
  {
    url: 'https://vimeo.com/103488483',
    title: 'Analog video art workshop #1',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-3fb716d2-db6f-4e46-9fdf-3bfbcf2b433a.jpg?w=600&e=webp'
  },

  {
    url: 'https://vimeo.com/544588649',
    title: 'Weakness of the flesh',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-89849a6b-2a34-4f7a-b5ba-aa4c226c5d52.jpg?w=600&e=webp'
  },
  {
    url: 'https://vimeo.com/142572817',
    title: 'Catalog 1961',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-22c851bb-0913-484e-ad69-694310d25d4d.jpg?e=webp'
  },
  {
    url: 'https://vimeo.com/480851897',
    title: 'Arabesque',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-5d3198d9-9ca8-47b2-a753-3ee2d29853b7.jpg?w=600&e=webp'

  },
  {
    url: 'https://vimeo.com/226138819',
    title: 'Begone Dull Care',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-1bb4be42-21c2-4553-ba46-a8e1f40c088a.jpg'
  },
  {
    url: 'https://vimeo.com/141717087',
    title: 'Sirenas',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-568cbf1c-a299-41fd-8b62-5a8d1d71f085.png?w=600&e=webp&nll=true&cX=583&cY=125&cW=1233&cH=1233'
  },
  {
    url: 'https://vimeo.com/141717087',
    title: 'Sirenas',
    img: 'https://d2kq0urxkarztv.cloudfront.net/56b2a814b5dedc9a24c33e9f/2600105/upload-568cbf1c-a299-41fd-8b62-5a8d1d71f085.png?w=600&e=webp&nll=true&cX=583&cY=125&cW=1233&cH=1233',
    pads_config: ['0:23', '0:25', '0:28', '0:52', '0:45', '0:55', '0:59'] // specify a set of timeframes attached to pads respectively 
  }
]

export const BASE_S3_URL =
  'https://ya-playtronica-test-bucket.s3.eu-central-1.amazonaws.com/'

export const about = {
  text: 'Welcome to Playtronica Video Sampler. Here you can remix short video clips using keyboard, midi devices or Playtron. We have collected some of the best video art and animation examples from the past years. Before making your remixes we encourage you to watch the original video and explore its creators. Many thanks to tubechopper.com for coming up with this original idea.'
}

export const help = {
  top: ['Use your keyboard (keys from <span>0</span> to <span>1</span>), any MIDI device or press color circles at the bottom of the screen to play the video. Use the <span>Space</span> button to stop video;',
    '<span>New.</span> Select a video from our library, or submit your own with a Vimeo link;',
    `<span>Share.</span> Share a link with selected video;`,
    `<span>About.</span> About the project;`,
    '<span>Rec.</span> Record session;',
    `<span>Set.</span> Assigns time from the video to each of the keys (1 to 0) or MIDI notes;`,
    '<span>Help.</span> Here you are!',
    '<span>Hide.</span> Hide all controls.'
  ],
  topMobile: ['Press color circles at the bottom of the screen to play the video. Tap on the video to start or stop;',
    '<span>New.</span> Select a video from our library, or submit your own with a Vimeo link;',
    `<span>Share.</span> Share a link with selected video;`,
    `<span>About.</span> Share a link with selected video;`,
    '<span>Help.</span> Here you are!',
    `<span>Set.</span> Assigns time from the video to each of the circle;`,
    '<span>Rec.</span> Record session;',
  ],
  mid: {
    header: `Press the bottom row of circles or use the top left part of your computer keyboard (A to P).  Turn on your speakers. Press ‘Space’ to start and stop the video.   `,
    //text: `Playtronica Sampler is here to play music online using your keyboard, MIDI device or Playtron. Can be used for music lessons, tinkering workshops and interactive performances. The musical content presented on this site is for non-commercial use only.`
  },
  bottom: ['Press to record a sequence']
}

export const pads = [
  { note: 'C', key: '1', time: '01:00', bg: "#4BACF8" },
  { note: 'C#', key: '2', time: '02:10', bg: "#57B9AA" },
  { note: 'D', key: '3', time: '03:15', bg: "#39802F" },
  { note: 'D#', key: '4', time: '02:16', bg: "#BED544" },
  { note: 'E', key: '5', time: '03:19', bg: "#FCE24C" },
  { note: 'F', key: '6', time: '01:24', bg: "#F3A33A" },
  { note: 'F#', key: '7', time: '02:29', bg: "#EE6941" },
  { note: 'G', key: '8', time: '03:32', bg: "#EB3223" },
  { note: 'G#', key: '9', time: '03:39', bg: "#F08EA4" },
  { note: 'A', key: '0', time: '02:43', bg: "#D032A8" },
  // { note: 'A#', key: 'g', time: '00:21' },
  // { note: 'B', key: 'h', time: '00:49' },
]

export const plyrConfig = {
  controls: ['play', 'mute', 'progress', 'volume', 'airplay', 'fullscreen'],
  muted: false,
  autoplay: true,
  volume: 0.8,
  quality: { default: 360, options: [1080, 720, 576, 480, 360, 240] },
  keyboard: { focused: false, global: false },
  loop: { active: true },
  playsinline: true,
  hideControls: false
}

export const keysAllowed = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0'
]

export const headerInfo = {
  title: 'Песни для любимых блюд',
  description:
    'Проект сервиса «Яндекс.Еда» и фруктово-овощных музыкальных инструментов Playtronica.',
}

export const introInfo = [
  'Музыканты за новогодним столом рассказали о любимой еде и сыграли ей серенаду на плейтроне. Подключайтесь к празднику и создайте свою новогоднюю мелодию на музыкальном семплере, в который мы загрузили звуки из культовых фильмов.',
]

export const samplerInfo = {
  moviesAbout:
    '«Ирония судьбы, или С лёгким паром!» (СССР, реж. Эльдар Рязанов), «Иван Васильевич меняет профессию» (СССР, реж. Леонид Гайдай), «Реальная любовь» (Великобритания, реж. Ричард Кёртис), «Полярный экспресс» (США, реж. Роберт Земекис), «Гарри Поттер и философский камень» (Великобритания, реж. Крис Коламбус), «Ёлки 2» (Россия, реж. Дмитрий Киселёв), «Офис» (США, реж. Пол Фиг), «Операция «Ы» и другие приключения Шурика» (СССР, реж. Леонид Гайдай), «Хроники Нарнии: Лев, колдунья и волшебный шкаф» (США, реж. Эндрю Адамсон), «Мистер Бин» (Великобритания, реж. Джон Биркин).',
  aboutBtn: 'menu',
  title: 'сыграй свой трек',
  ads: 'Эти фильмы можно посмотреть на Кинопоиск HD с подпиской Яндекс.Плюс.',
  description:
    'Прежде чем подключать провода к любимой еде, потренируйтесь на блюдах из новогодней киноклассики. Мы пересмотрели наши любимые праздничные фильмы — от «Иронии Судьбы» до рождественского эпизода «Офиса» — и сделали телесемплер.',
  description2:
    'Что делать: выбирайте грув, входите в ритм и жмите на клавиши — на экране появятся знакомые герои и угощения. Несколько попыток, и фраза «Какая гадость эта ваша заливная рыба» превратится в хип-хоп зарисовку.',
}

export const padColors = [
  '#FF5449',
  '#A4DD5C',
  '#FF965A',
  '#92BDFF',
  '#FF8787',
  '#58BFE0',
  '#58BFE0',
  '#FF8787',
  '#92BDFF',
  '#FF965A',
  '#A4DD5C',
  '#FF5449',
]

export const videoUrl = BASE_S3_URL + 'edaxPlaytronica.mp4'

export const aboutInfo = {
  header: 'песни для любимой еды',
}

const loop1 = 'loop1.mp3'
const loop2 = 'loop2.mp3'
const loop3 = 'loop3.mp3'
const loop4 = 'loop4.mp3'

export const drumsConfig = [
  {
    title: 'трэп',
    src: loop1,
    name: 'loop1',
  },
  {
    title: 'хаус',
    src: loop2,
    name: 'loop2',
  },
  {
    title: 'техно',
    src: loop3,
    name: 'loop3',
  },
  {
    title: 'брейк',
    src: loop4,
    name: 'loop4',
  },
]
