import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import * as Tone from "tone";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pads from "../Pads";
import Header from "../../components/Header";
import Sequencer from "../../components/Sequencer";
import { pads, plyrConfig, hints, videos} from "../../config";
import {
  isSSR,
  useKeyboradListener,
  playWithKey,
  toneHelper,
} from "../../utils";
import * as styles from "./style.module.css";
import sequenceGenerator from "../../utils/sequenceGenerator";
import timeToSeconds from "../../utils/timeToSeconds";
import midiHelper from "../../utils/midiHelper";

let player;

const VideoContainer = () => {
  // console.log("VIDEOCONTAINER UPDATE");

  const containerRef = useRef(null);
  const [error, setError] = useState(null);
  const [videoId, setVideoId] = useState(videos[0].url);
  const [loaded, setLoaded] = useState(false);
  const keyPressed = useKeyboradListener()[0];
  const shouldSequencerUpdate = useKeyboradListener()[1];

  const [binding, setBinding] = useState(false);

  //sequencer
  const [sequence, updateSequence] = useState([]);
  const [recording, toggleRecording] = useState(false);
  const [recordedNote, recordNote] = useState(null);
  const [currentLoopId, updateLoop] = useState(1);
  const [currentNote, updateCurrent] = useState(0);
  const [tick, updateTick] = useState(0);
  const [currentLoop, changeLoop] = useState({});
  const [step, updateStep] = useState(0);
  const [padArray, setPads] = useState([]);
  const [isUIHidden, hideUI] = useState(false);

 

  useEffect(() => {
    if (isSSR()) return;
    setPads(pads)
    const Plyr = require("plyr");
    player = new Plyr("#player", plyrConfig);
    player.on("ready", onPlayerReady);
    player.play();
    window.playerPlayr = player
    let url = new URL(window.location.href);
    let vidConfigId = url.searchParams.get("vid");
    vidConfigId && changeVideoId(vidConfigId);
  }, []);

  useEffect(() => {
    isUIHidden ? 
    player.toggleControls(false)
    : player.toggleControls(true)
  },[isUIHidden])

  useEffect(() => {
    if (keyPressed) {
      handlePress(keyPressed);
    }
  });

  useEffect(() => {
    console.log(recordedNote)
    let new_seq = sequence
    //new_seq[step] = recordedNote
    //if (recording) updateSequence(new_seq);
  }, [step]);

  useEffect(() => {
    if (true || sequence.length > 0) {
      Tone.Transport.cancel(currentLoop);
      const loop = new Tone.Loop((time) => {
        updateTick(time);
        let newstep = step + 1
        if (!recording && step + 1 > sequence.length - 1) {
          newstep = 0
          updateStep(0)
          return
        }
        updateStep(newstep);
        console.log("Step:" + newstep + " Length:" + sequence.length);
        sequence[newstep] && seekTime(sequence[newstep], false);
      }, "8n").start(0);
      Tone.Transport.start(0);
      changeLoop(loop);
    }
  }, [tick, recording, step]);


  const handlePress = (e) => {
    if (e.code === "Space") {
      player.togglePlay();
    }
    if (binding) {
      let pad = pads.findIndex(i => {
       return i.key == e.key
      });
      if (pad != -1) {
        pads[pad].time = player.currentTime;
      }
      setBinding(false);
    } else {
      playWithKey(e.keyCode, pads, seekTime);
    }
  };

  const notify = () =>
    !window.mobileAndTabletCheck() &&
    toast(<div className={styles.toast}>{hints.toast}</div>, {
      toastId: "1",
      delay: 4000,
    });

  function onPlayerReady(event) {
    if (player) {
      player.quality = 360;
      toneHelper(player)
      var url = new URL(window.location.href);
      player.embed.getDuration().then(function (duration) {
        let ratio = duration / pads.length;
        console.log(ratio);

        midiHelper(duration, seekTime)

        var padsConfig = url.searchParams.get("pads");
        if (padsConfig) {
          let decodedConfig = window.decodeURIComponent(
            window.atob(padsConfig)
          );
          console.log(decodedConfig.split(","));
          pads.map((item, key) => {
            item.time = Number(decodedConfig.split(",")[key]);
          });
        } else {
          pads.map((item, key) => {
            item.time = Number((ratio * key + 1).toFixed(2));
          });
        }
      });
    }

    setError(false);
  }

  useEffect(() => {
    if (error !== false || isSSR()) return;
  }, [error]);

  const stopVideo = () => player && player.stop();

  const playVideo = () => player && player.play();

  const seekTime = (time, isManual = true) => {
    if (player) {
      player.currentTime = time;
      player.muted = false;
      player.paused && playVideo();
      updateCurrent(time);
      let newTime = sequence
      newTime[step] = time
      recording && isManual && updateSequence(newTime);
      if(!player.playing) {
        player.embed.play()
      }
    }
  };

  const handleLoaded = () => {
    setLoaded(true);
  };

  const timeChange = (pad) => {
    let mod = 0.0;
    pad.time = player.currentTime - mod;
    return pad;
  };

  const changeVideoId = (videoId) => {
    if (!player) return;
    setVideoId(videoId);
    player.source = {
      type: "video",
      sources: [
        {
          src: videoId,
          provider: "vimeo",
        },
      ],
    };

    let currentVideo = videos.filter(item => item.url == videoId)[1]
    if (currentVideo.pads_config) {
      console.log("CHANGING PADS")
      currentVideo.pads_config.map((pad,key) => {
        padArray[key].time = timeToSeconds(pad)
      })
      setPads(pads)
      console.log(pads)
    }    
  };

  return (
    <div>
      <div
        id="sampler"
        className={cn(
          styles.embedResponsive,
          styles.sampler,
          styles.embedResponsive4by3
        )}
        ref={containerRef}
      >
        <div className={styles.embedResponsiveItem}>
          <Header
            pads={padArray}
            sequence={sequence}
            setBinding={setBinding}
            setPads={setPads}
            binding={binding}
            timeChange={timeChange}
            videoId={videoId}
            changeVideo={changeVideoId}
            hideUI={hideUI}
            isUIHidden={isUIHidden}
          />

          {
            <Sequencer
              sequence={sequence}
              updateSequence={updateSequence}
              isRecording={recording}
              recordedNote={recordedNote}
              recordNote={recordNote}
              toggleRecording={toggleRecording}
              pads={pads}
              seekTime={seekTime}
              isUIHidden={isUIHidden}
            />
          }
          <div
            id="player"
            data-plyr-provider="vimeo"
            data-plyr-embed-id={videoId}
          ></div>
        </div>

        <ToastContainer
          className={styles.toaster}
          position="bottom-center"
          autoClose={10000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {error === false && (
          <Pads
            playerHandler={seekTime}
            timeChange={timeChange}
            notify={notify}
            setBinding={setBinding}
            pads={padArray}
            binding={binding}
            isUIHidden={isUIHidden}
          />
        )}
      </div>
    </div>
  );
};

export default VideoContainer;
