export default (pads, length) => {
    if (!pads) return []
    let sequence = []
    let kick = pads[rand(0,pads.length-1)].time
    let snare = pads[rand(1,pads.length-1)].time
    for (var i = 0; i < 64; i++) {
        if (i%4===0) {
            sequence.push(kick)
        }
        else if (i%rand(2,3)==0) {
            sequence.push(snare)
        } else {
            sequence.push('')
        }
    }
    return sequence
}

function rand(min, max) {
    return (Math.random() * (max - min) + min).toFixed(0)
}