import React, { useState } from 'react'
import cn from "classnames"
import * as styles from './styles.module.css'
import Pad from './Pad'
import { timeToSeconds } from '../../utils'

const Pads = ({ isUIHidden, pads, playerHandler, notify, timeChange, binding, setBinding, recording, recordNote }) => {
  const [padPressed, pressPad] = useState(0)
  const handleClickCta = () => {
    pressPad(1)
    playerHandler(timeToSeconds('00:32'))
    notify()
  }
  // console.log('PADS UPDATE');
  return (
    <div id="pads" className={cn (
      styles.container,
      isUIHidden && styles.hideUI
    )}>
        <div className={styles.padsWrapper}>
          {pads.map((pad, key) => (
            <Pad
              playerHandler={playerHandler}
              pad={pad}
              timeChange={timeChange}
              key={key}
              setBinding={setBinding}
              uniqId={key}
              binding={binding}
            />
          ))}
        </div>
    </div>
  )
}

export default Pads
